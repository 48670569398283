@import '../utils.sass';
@import '~bulma/sass/components/dropdown.sass';

.dropdown-item {
  cursor: pointer;
  &:hover {
    background-color: $dropdown-item-hover-background-color;
    color: $dropdown-item-hover-color; }
  &.is-active {
    background-color: $dropdown-item-active-background-color;
    color: $dropdown-item-active-color; }
  * {
    color: $dropdown-item-color; } }
